/*global process*/
/*eslint no-undef: "error"*/
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  concat,
  HttpLink,
} from "@apollo/client";
import Cookies from "js-cookie";

const httpLink = new HttpLink({
  uri: process.env.API_ENDPOINT,
});
const authMiddleware = new ApolloLink((operation, forward) => {
  let headers = Cookies.get("access_token");
  const token = headers;
  operation.setContext({
    headers: {
      authorization: token ? token : "",
    },
  });
  return forward(operation);
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
export const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache({}),
  defaultOptions: defaultOptions,
});
